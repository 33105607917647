import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function BlogAKN() {
	
	return (
		<Article
			title='My experience of curing my AKN (Acne behind neck)'
			takeaway_title='Measures to cure AKN'
			page_id='Blog - AKN'
			read_time='5 mins'
			author='Brij Bhushan'
			image1='/akn-before-after.jpg'
			image1_description='This is how my neck looked before & after these diet changes'
			image2='/akn-after-laser.jpg'
			image2_description='This is how my neck looked after the laser treatment'
			toggle_article='Solution'
			toggle_url='/article/soln_akn'
			related_article='Solution - AKN'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p className='article_para'>
						I am Indian and suffered from AKN for roughly 18 yrs. Finally, I have managed to cure it and here I will share with you my journey from the beginning of the disease till its 100% cure.
					</p>
					<p className='article_para'>
						AKN is short for Acne Keloidalis Nuchae. It is also referred as Folliculitis Keloidalis Nuchae. In simple words, unlike acne, here it is not skin which gets inflamed & damaged, rather it is the wall of hair follicle which gets inflamed & damaged. That’s why, even though it feels like acne, it behaves very differently. Usually, when an acne has fully grown and you take out its puss, then it starts healing. Behaviour of AKN is quite different. When you try to take its puss out, it continues to swell and starts depositing keloid-like dark tissue over the affected area. More you scratch it, the more it deposits this dark tissue.
					</p>
					<p className='article_para'>
						When I was 26 yrs old, I got one acne at the back of my neck. Of course, I couldn’t see it even with the help of a mirror. When I thought it had fully grown in size, I scratched it to pull out the accumulated puss. Instead of yellowish puss what came out was red colored liquid. I thought, sometimes puss is red also. I thought, now it will heal & in a few days skin will be normal. In the next few days, I felt again there was an acne behind my neck and again I did the same. I was so naive in those days that I kept scratching it again and again for a whole year. After a year, I happened to see the skin of the affected area with the help of two mirrors, installed in the bathroom of the hotel where I had checked in. It was a shocking sight. There were multiple dark tissue deposits spread in the middle of the back of my neck, with some of them having a dark crust on them. Though I still couldn’t understand what I was dealing with, it was clear that my scratching had been aggravating the condition.
					</p>
					<p className='article_para'>
						Now, I take a moment to speculate what might have caused it. Here are the possibilities:
						<ol className='article_list'>
							<li>The first acne appeared after my visit to the USA, where I had also taken a haircut. Maybe the barber clipped my hairline too short which caused the inflammation, or maybe I got an infection from the clipper. (This I speculate because AKN is reported more in USA than in India)</li>
    						<li>Maybe it was an ordinary acne eruption but it was my continuous scratching that caused the follicle damage.</li>
    					</ol>
    					I can only speculate and I may never know how I got the AKN like none of the experts know what causes it. I may not have thick skin and thick curly hair but I live in a hot climate country, I had a stressful job and a weak immunity. I guess, these 3 conditions out of the total 5, were sufficient to make sure that I continued to suffer from it once I had the problem.
					</p>
					<p className='article_para'>
						Now, let's come back to my story. So, once I saw the affected skin with the help of two mirrors, I started controlling the urge to scratch them. Despite that there was no improvement & often I used to observe some oozing in the affected area. After a few months, I consulted a dermatologist. She started cryotherapy with liquid nitrogen. Lesser I speak about that treatment better it is, because it was a painful & long treatment. Moreover, it didn’t help a bit. The dermatologist had said if cryotherapy doesn’t help, then the next option is to inject steroids into the eruptions and that will require anesthesia. So, I decided to consult another dermatologist in a different hospital which was rated best in the country, for the skin treatment. Here the dermatologist prescribed me a topical cream called Fucibet. It is a combination of an antibiotic & a steroid. This cream worked like a miracle. All existing acne got pacified within a week. However, new ones kept appearing every now & then, and the ugly fibrosis marks remained as it is.
					</p>
					<p className='article_para'>
						For the next 6 years, I continued managing the AKN with the miracle drug Fucibet. So, there were hardly any new eruptions, though the fibrosis marks remained. Then I consulted another dermatologist in a different town with a hope to get rid of the marks. This time I went through a laser treatment for removal of the fibrosis. However, it increased the inflammation and the AKN eruptions were back with full force as if the problem had never gone anywhere rather it was just sitting under the layers of fibrosis.
					</p>
					<p className='article_para'>
						By this time, cryotherapy, laser treatment, retinoid cream, antibiotics and steroids, all have been tried on my AKN problem and the only remaining option was surgery. However, the surgery is recommended only when the severity of the problem is very high. So, I decided to continue containing the problem with the Fucibet cream and continued hiding the fibrosis marks under my hair.
					</p>
					<p className='article_para'>
						Then I tried alternate treatment of homeopathy. It is said that homeopathy is a slow treatment and it takes time to deliver results. However, when it didn’t produce any results in 3 long years, I discontinued it.
					</p>
					<p className='article_para'>
						Now, when I was in my early 40s, I started researching various health topics as I have mentioned in my other blogs. That’s when I made the following changes in my diet & lifestyle:
						<ol className='article_list'>
							<li>No scratching of acne</li>
    						<li>No refined white sugar</li>
    						<li>Not drinking water before & after meals</li>
    						<li>No snacking between meals</li>
    						<li>Properly chewing the food</li>
    						<li>No alcohol</li>
    						<li>No fruit juice</li>
    						<li>Good sleep at night</li>
    						<li>Regular exercise</li>
    						<li>Either don’t drink milk or consume it in the correct way</li>
    						<li>Daily fast for 14 hrs (No eating from 8 PM to 10 AM)</li>
    					</ol>
					</p>
					<p className='article_para'>
						(For explanation of each principle and details on their rationale & references, one should refer to my other article on the AKN solution <Link to='/article/soln_akn'>here</Link>)
					</p>
					<p className='article_para'>
						Within a year of absorbing these changes in my diet & lifestyle, new acne stopped appearing (on the back of my neck) and old fibrosis deposits disappeared slowly & gradually. Now, my skin at the back of the neck is as healthy as any healthy person can have. For the last 2 years, I haven’t applied any medicine or taken any treatment for AKN. However, the 11 principles mentioned above continue to be an integral part of my life.
					</p>
			</div>
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: "Don't scratch the acne & avoid rubbing on them",
	},
	{
		takeaway: "Don't shave the hair in the affected area",
	},
	{
		takeaway: "Don't consume sugar & products that contain sugar",
	},
	{
		takeaway: "Don't drink water before & after meals",
	},
	{
		takeaway: "Don't eat snacks between meals",
	},
	{
		takeaway: "Chew your food properly",
	},
	{
		takeaway: "Don't consume alcohol",
	},
	{
		takeaway: "Don't drink fruit juice",
	},
	{
		takeaway: "Have sufficient sleep at night",
	},
	{
		takeaway: "Exercise regularly",
	},
	{
		takeaway: "Either don't drink milk or consume it in the correct way",
	},
	{
		takeaway: "Daily fast for 14 hrs (No eating from 8 PM to 10 AM)",
	},
];

const RelatedArticles = [
	{
		title: 'Solution - AKN',
		path: '/article/soln_akn',
	},
];